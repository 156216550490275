<template>
    
    <Section :options="sectionOptions">

            <form id="register-form" name="register-form" class="register-form mb-0" action="#" method="post" @submit="onRegisterFormSubmit">

                <h3 v-if="labels.title && labels.title != ''">{{ labels.title }}</h3>

                <p v-if="labels.text && labels.text != ''">{{ labels.text }}</p>

                <validation-observer v-slot="{ invalid }" slim>
                    <div class="row">
                        <TextField 
                            v-bind:value.sync="form.email" 
                            id="email" 
                            :label="labels.email" 
                            :placeholder="labels.email_placeholder" 
                            :rowClass="{'col-lg-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            fieldType="email"
                            required 
                        />

                        <TextField 
                            v-bind:value.sync="form.password" 
                            :label="labels.password" 
                            :placeholder="labels.password_placeholder" 
                            :rowClass="{'col-lg-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            fieldType="password"
                            validationMode="aggressive"
                            :validationRules="passwordValidationRules"
                        />

                        <TextField 
                            v-bind:value.sync="form.confirmPassword" 
                            :label="labels.confirmPassword" 
                            :placeholder="labels.confirmPassword_placeholder" 
                            :rowClass="{'col-lg-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            fieldType="password"
                            validationMode="aggressive"
                            :validationRules="confirmPasswordValidationRules"
                        />

                        <div class="w-100">
                            <hr />
                        </div>

                        <TextField 
                            v-bind:value.sync="form.profile.firstname" 
                            id="firstname" 
                            :label="labels.firstname" 
                            :placeholder="labels.firstname_placeholder" 
                            :rowClass="{'col-lg-6':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            required 
                        />

                        <TextField 
                            v-bind:value.sync="form.profile.lastname" 
                            id="lastname" 
                            :label="labels.lastname" 
                            :placeholder="labels.lastname_placeholder" 
                            :rowClass="{'col-lg-6':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            required 
                        />

                        <div class="w-100"></div>

                        <Address 
                            v-bind:value.sync="form.profile.address" 
                            :options="addressOptions"
                            :labels="addressLabels"
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            :rowClass="{'col-12':true, 'form-group':true}" 
                            required
                        />

                        <Phone 
                            id="phone" 
                            v-bind:value.sync="form.profile.phone" 
                            :label="labels.phone" 
                            :rowClass="{'col-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            required 
                        />

                        <CmsComponent v-if="components && components.meta" :component="components.meta" v-bind:value.sync="form.meta" ></CmsComponent>

                        <div class="col-12 form-group">
                            <button :disabled="invalid" class="button button-3d button-black m-0 ladda-button" data-style="zoom-in" id="register-form-submit" name="register-form-submit" value="register" type="submit">{{labels.button}}</button>
                        </div>
                    </div>
                </validation-observer>

            </form>

    </Section>

</template>

<style>

.register-form {
    min-height:50vh;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Address from '@fwk-client/components/panels/input/Address.vue';
import Phone from '@fwk-client/components/panels/input/Phone.vue';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { extend } from "@fwk-node-modules/vee-validate";
import { languagesTypes } from '@fwk-client/store/types';
import { Component } from '@fwk-client/modules/cms/helpers/components';

/** @cmsOptions */
export interface RegisterOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface RegisterLabels {
    
}

/** @cmsSlots */
export interface RegisterSlots {
    meta?:Component
}

export default defineComponent({
    props: {
        options: Object as PropType<RegisterOptions>,
        labels: {
          type: Object as PropType<RegisterLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<RegisterSlots>
    },
    components: {
        Section,
        TextField,
        Address,
        Phone
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const form = {
            email: "",
            password : "",
            confirmPassword:"",
            profile: {
                address:{
                    addressLines:[], 
                    postalCode:"", 
                    city:"", 
                    countryCode:""
                },
                fistname: "",
                lastname: "",
                phone:{
                    type:"PERSONAL",
                    number:""
                }
            },
            meta: {
            }
        }

        const addressOptions = {
            showName: false,
            showNote: false,
            labelAsColumn: false,
            nbAddressLines: 2,
            listCountries: "WORLD"
        }

        const addressLabels = {
            addressLines:[
                app.$t("authentication.register.form.address.addressLine")
            ],
            addressLines_placeholder:[
                app.$t("authentication.register.form.address.addressLine1_placeholder"),
                app.$t("authentication.register.form.address.addressLine2_placeholder")
            ],
            postalCode:app.$t("authentication.register.form.address.postalCode"),
            postalCode_placeholder:app.$t("authentication.register.form.address.postalCode_placeholder"),
            city:app.$t("authentication.register.form.address.city"),
            city_placeholder:app.$t("authentication.register.form.address.city_placeholder"),
            country:app.$t("authentication.register.form.address.country"),
            country_select:app.$t("authentication.register.form.address.country_select")
        }

        var laddaSubmit:Ladda.LaddaButton|null = null;

        const labels = {
            title: app.$t('authentication.register.title'),
            text: app.$t('authentication.register.text'),
            email: app.$t('authentication.register.form.email'),
            email_placeholder: app.$t('authentication.register.form.email_placeholder'),
            firstname: app.$t('authentication.register.form.firstname'),
            firstname_placeholder: app.$t('authentication.register.form.firstname_placeholder'),
            lastname: app.$t('authentication.register.form.lastname'),
            lastname_placeholder: app.$t('authentication.register.form.lastname_placeholder'),
            phone: app.$t('authentication.register.form.phone'),
            password: app.$t('authentication.register.form.password'),
            password_placeholder: app.$t('authentication.register.form.password_placeholder'),
            confirmPassword: app.$t('authentication.register.form.confirmPassword'),
            confirmPassword_placeholder: app.$t('authentication.register.form.confirmPassword_placeholder'),
            button: app.$t('authentication.register.button')
        }
        

        const computedOptions:RegisterOptions = {
            sectionOptions : {
                
            },
            ...props.options
        };
    
        extend('password',{
            validate(password:string, params:any):Promise<boolean|string> {

            if(password != "" && password.length < 5) {
                return Promise.resolve(app.$t("authentication.error.password-incorrect") as string);
            }
            return Promise.resolve(true);
            }
        });

        extend('confirmPassword',{
            validate(confirmPassword:string, params:any):Promise<boolean|string> {

            if(form.password != "" && confirmPassword != "" && form.password != confirmPassword) {
                return Promise.resolve(app.$t("authentication.error.confirmPassword-different") as string);
            }
            return Promise.resolve(true);
            }
        });

        const passwordValidationRules = {
            "required" : true,
            "password" : true
        }

        const confirmPasswordValidationRules = {
            "required" : true,
            "confirmPassword" : true
        }

        const onRegisterFormSubmit = (evt:Event) => {
            evt.preventDefault();

            var options:api.ApiVueOptions =  {
                app
            }

            var input = {
                ...form
            }

            laddaSubmit!.start();
            
            api.postAPI('/api/auth/register', input, options).then((response:any) => {

                if(response.registered) {
                    // We switch to the register completed page
                    var languageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
                    $router.push({
                        name : "registered",
                        params : {
                            lang : languageCode,
                        }
                    }).catch((err:any) => {});
                }

                laddaSubmit!.stop();
            });
        }

        onMounted(() => {
            var button:HTMLButtonElement|null = document.querySelector( 'form[name=register-form] button.ladda-button' );
            laddaSubmit = Ladda.create(button!);
        })

        onBeforeUnmount(() => {
        })

        return {
            sectionOptions:computedOptions.sectionOptions,
            form,
            passwordValidationRules,
            confirmPasswordValidationRules,
            labels,
            addressLabels,
            addressOptions,
            onRegisterFormSubmit
        }

    }
})
</script>