<template>
  
    <validation-provider :name="id" mode="eager" ref="input" :rules="intlTelValidationRule" v-slot="{ errors, classes, validate }" slim>
      <div :class="{...rowCSSClass, ...classes}">
        <label v-if="listPhoneTypes.length == 1" :for="phoneFieldId" :class="{...labelCSSClass}">{{getPhoneLabel(listPhoneTypes[0])}} <small v-if="required">*</small></label>
        <div :class="{...fieldCSSClass}">
          <div v-if="listPhoneTypes.length > 1" class="col-lg-3">
            <v-select :options="listPhoneTypes" :getOptionLabel="getPhoneLabel" :id="id+'_type'" :placeholder="$t('phones.form.type_placeholder')" @keypress.enter.native.prevent="" v-model="input.type"/>
          </div>
          <input v-if="listPhoneTypes.length == 1" type="tel" :id="phoneFieldId" :class="{...inputCSSClass, 'required':required, ...classes}" v-on:input="onPhoneUpdate($event) || validate($event)" v-on:blur="validate($event)" :required="required" />
          <div v-if="listPhoneTypes.length > 1" class="col-lg-9">
            <input type="tel" :id="phoneFieldId" :class="{...inputCSSClass, 'required':required, ...classes}" v-on:input="onPhoneUpdate($event) || validate($event)" v-on:blur="validate($event)" :required="required" />
          </div>
          <span :class="{...controlCSSClass}" style="display:block;" v-if="errors.length > 0">{{ errors[0] }}</span>
        </div>
      </div>
    </validation-provider>

</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { extend } from "vee-validate";
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';

@Component({
  components: { }
})
export default class Phone extends mixins<GenericInput<any>>(GenericInput) {

  input:any = { // The form contains the updated phone
    type: (this.value && this.value.type) ? this.value.type :"",
    number: (this.value && this.value.number) ? this.value.number :"",
  }; 

  listPhoneTypes:any[] = (this.value && this.value.type) ? [this.value.type] :[];

  intlTel:intlTelInput.Plugin|null = null;

  getPhoneLabel(typeCode:string) {
    if(this.label) {
      return this.label;
    }
    else if(this.$te('phones.types.'+typeCode)) {
        return this.$t('phones.types.'+typeCode);
    }
    return typeCode;
  }

  get phoneFieldId() {
    if(this.id) {
      return this.id;
    }
    if(this.listPhoneTypes.length == 1) {
      return 'phone_'+this.listPhoneTypes[0];
    }
    else {
      return 'phone_number';
    }
  }

  created() {
    this.updateListPhoneTypes();
    this.addPhoneValidation();
  }

  mounted() {
    // We load the intlTel component
    var intlTel = intlTelInput(document.querySelector("#"+this.phoneFieldId) as Element, {
        preferredCountries : ['fr'],
        allowDropdown : true,
        // @ts-ignore
        customContainer : "flag-left"
      });
    if(this.value.number != "") {
      intlTel.setNumber(this.value.number);
    }
    this.intlTel = intlTel;

    // We check if fields are prefilled to validate them directly
    if(this.input.number != "" && this.$refs.input) {
      this.updatePhoneNumber(this.input.number);
      this.validate();
    }
  }

  beforeDestroy() {
    if(this.intlTel) {
      this.intlTel.destroy();
    }
  }

  updateListPhoneTypes() {
    if(this.listPhoneTypes.length != 1) {
      // We need to get the list of available companies for the current logged in user
      var options:api.ApiVueOptions =  {
        app: this
      }
      api.getAPI('/api/utils/listPhoneTypes', options).then((response:any) => {
        if(response.phoneTypes) {  
          this.listPhoneTypes = response.phoneTypes;
        }
      });
    }
  }

  get intlTelValidationRule() {
    var validation:any = {
      intlTel: { 
        field: this.intlTel
      }
    }
    if(this.required) {
      validation = {
        ...validation,
        "required" : true
      }
    }
    return validation;
  }

  addPhoneValidation() {
    var componentInstance = this;
    extend('intlTel',{
      params: ['field'],
      validate(phoneNumber, params):Promise<boolean|string> {

        // @ts-ignore
        var intlTel = params.field;

        if(componentInstance.input.number != "" &&
            intlTel != null && 
            !intlTel.isValidNumber()) {
          return Promise.resolve(componentInstance.$t("phones.errors.invalid") as string);
        }
        
        return Promise.resolve(true);
      }
    });
  }

  onPhoneUpdate(event:Event) {
    //@ts-ignore
    var number = event.target.value;
    this.updatePhoneNumber(number);
  }

  updatePhoneNumber(inputValue:string) {
    if(this.intlTel) {
      inputValue = this.intlTel.getNumber();
    } 
    Vue.set(this.input,'number', inputValue);
    // @ts-ignore
    Vue.set(this.$refs.input,'value',inputValue);
  }

  validate() {
    // @ts-ignore
    this.$refs.input.validate();
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListPhoneTypes();
  }
  
}
</script>