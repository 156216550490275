<template>
  <validation-provider :name="inputId" mode="eager" ref="input" :rules="computedValidationRules" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label :for="inputId" :class="labelCSSClass">{{inputLabel}} <small v-if="required">*</small></label>
      <div :class="fieldCSSClass">
        <v-select ref="listCountries" 
            :options="listCountries" 
            :reduce="country => country.code"
            :class="{...inputCSSClass, 'required':required, ...classes}"
            label="label" 
            :id="inputId" 
            :placeholder="inputPlaceholder" 
            @keypress.enter.native.prevent="" 
            v-model="input">
          <template v-if="required" #search="{attributes, events}">
            <input
              :class="{'vs__search':true}"
              :required="!input"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
        <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';
import * as api from '@fwk-client/utils/api';

@Component({
  components : {}
})
export default class Country extends mixins<GenericInput<string>>(GenericInput) {

  @Prop({
    type: String,
    required: false,
    default: "EU"
  }) readonly type!: "EU"|"WORLD"

  inputId = "country";

  listCountries:any[] = [];

  get inputLabel() {
    return (this.label && this.label != "") ? this.label : this.$t('address.form.country') as string;
  }

  get inputPlaceholder() {
    return (this.placeholder && this.placeholder != "") ? this.placeholder : this.$t('address.form.country_select') as string;
  }

  created() {
    if(this.id) { this.inputId = this.id }
    this.updateListCountries();
  }

  updateListCountries() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }

    var endpoint = "listEUCountries";
    if(this.type != "EU") {
      endpoint = "listCountries";
    }

    api.getAPI('/api/utils/' + endpoint, options).then((response:any) => {
      if(response.countries) { 
        this.listCountries = response.countries;
        this.updateSelectedFromInput();
      }
    });
  }

  updateSelectedFromInput() {
    if(this.input && this.input != "") {
      // We get the value from countryCode
      var selected = this.listCountries.find((element) => {
        return element.code.toLowerCase() == this.input.toLowerCase();
      });
      if(selected && this.$refs.listCountries) {
        // @ts-ignore
        this.$refs.listCountries.updateValue(selected);
      }
      else {
        this.input = "";
      }
    }
  }

  validate() {
    // @ts-ignore
    return this.$refs.input.validate();
  }

  @Watch('value')
  onValueChange(val: any, oldVal: any) {
    this.input = val;
    this.updateSelectedFromInput();
  }

  @Watch('input')
  onInputChange(val: any) {
    // @ts-ignore
    this.$refs.input.validate(val);
    this.$emit('update:value', val);
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListCountries();
  }
  
}
</script>